<template>
  <div class="bg-darkgray-900 relative" style="background-size:100% 100%;background-image:url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAgICAgJCAkKCgkNDgwODRMREBARExwUFhQWFBwrGx8bGx8bKyYuJSMlLiZENS8vNUROQj5CTl9VVV93cXecnNEBCAgICAkICQoKCQ0ODA4NExEQEBETHBQWFBYUHCsbHxsbHxsrJi4lIyUuJkQ1Ly81RE5CPkJOX1VVX3dxd5yc0f/CABEIABcAIAMBIgACEQEDEQH/xAAuAAABBQEAAAAAAAAAAAAAAAAAAQIDBAYFAQEBAQAAAAAAAAAAAAAAAAAEAQL/2gAMAwEAAhADEAAAAJqGEa0ms4eboSU4AK1qBmf/xAAfEAABAwUAAwAAAAAAAAAAAAABAAIDBAURITETMlL/2gAIAQEAAT8AlkikHsnCMZ2nTsa/qu1awBRXiXG3KS9v+k67PL+quq3SjqNS7Gl5X52USSUSSv/EABcRAAMBAAAAAAAAAAAAAAAAAAABFDH/2gAIAQIBAT8ArZWx6PT/xAAZEQACAwEAAAAAAAAAAAAAAAAAAQIEERT/2gAIAQMBAT8AdGJwxNZp/9k=');">
    <div class="z-0">
      <ClientOnly>
        <Transition name="fade">
          <img v-if="isIdle" class="absolute bottom-0 inset-0 object-cover top-0 left-0 right-0 h-full w-full max-w-none z-0" :src="bg" :srcset="srcset" alt="Pricing plans background" />
        </Transition>
      </ClientOnly>
      <div class="z-10 absolute bottom-0 inset-0 bg-gradient-to-t from-darkgray-900/95 via-darkgray-900/20 to-darkgray-900/20" />
    </div>
    <div class="relative pt-24 pb-96 lg:pt-36 z-10">
      <div class="relative mx-auto max-w-7xl px-6 text-center lg:px-8">
        <div class="mx-auto max-w-2xl lg:max-w-4xl">
          <h1 class="text-xl">
            MattePaint Plans
          </h1>
          <p class="mt-2 text-4xl md:text-5xl font-bold tracking-tight text-white text-shadow">
            Simple plans, <br class="hidden sm:inline lg:hidden" />whoever you are<span class="text-orange-500">.</span>
          </p>
          <p class="mt-6 text-lg leading-8 text-lightgrey-100 font-bold text-shadow">
            No lock-in contracts, cancel anytime.
          </p>
        </div>
      </div>
    </div>
    <div class="flow-root pb-12 md:pb-32 lg:pb-40 z-10">
      <div class="relative -mt-80">
        <div class="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 lg:gap-8">
            <div v-for="tier in tiers" :key="tier.name" class="flex flex-col rounded-3xl bg-darkgray-500 shadow-xl">
              <div class="p-8 sm:p-10">
                <h3 :id="tier.id" class="text-lg font-semibold leading-8 tracking-tight text-orange-400 uppercase">
                  {{ tier.name }} License
                </h3>
                <div class="mt-0 flex items-baseline text-md font-normal tracking-tight text-darkgray-100">
                  Prices starting from
                </div>
                <div class="mt-2 flex items-baseline text-5xl font-bold tracking-tight text-lightgrey-100">
                  {{ currencySymbol }}{{ tier.priceMonthly }}
                  <span class="text-lg font-semibold leading-8 tracking-normal text-lightgrey-200">/mo</span>
                </div>
                <p class="mt-6 text-base leading-7 text-lightgrey-200">
                  {{ tier.description }}
                </p>
              </div>
              <div class="flex flex-1 flex-col p-2">
                <div class="flex flex-1 flex-col justify-between rounded-2xl bg-darkgray-600 p-6 sm:p-8">
                  <ul role="list" class="space-y-6">
                    <li v-for="feature in tier.features" :key="feature" class="flex items-start">
                      <div class="flex-shrink-0">
                        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                      </div>
                      <p class="ml-3 text-sm leading-6 text-lightgrey-200">
                        {{ feature }}
                      </p>
                    </li>
                    <!-- <li v-if="(tier.id === 'tier-personal')" class="flex items-start">
                      <div class="flex-shrink-0">
                        <XMarkIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <p class="ml-3 text-sm leading-6 text-lightgrey-200">
                        Commercial use
                      </p>
                    </li> -->
                  </ul>
                  <div class="mt-8">
                    <NuxtLink :to="tier.href" class="inline-block w-full rounded-lg bg-orange-500 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-orange-600 uppercase" :aria-describedby="tier.id">
                      View {{ tier.name }} Plans
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'

import xlbg from '~/assets/img/home/prices/2x.jpg';
import lbg from '~/assets/img/home/prices/1x.jpg';
import mbg from '~/assets/img/home/prices/mobile.jpg';

export default {
  components: { CheckIcon, XMarkIcon },
  data() {
    return {
      bg: mbg, // default image
      srcset: `${mbg} 500w, ${lbg} 1024w, ${xlbg} 1920w`,
    };
  },
  computed: {
    artistPlanData() {
      return this.$store.base.getPlanData('artist');
    },
    indiePlanData() {
      return this.$store.base.getPlanData('enterprise-indie');
    },
    tiers() {
      const artistPrice = Math.round(this.artistPlanData.terms.year.cost[this.currency] / 100 / 12);
      const indiePrice = Math.round(this.indiePlanData.terms.year.cost[this.currency] / 100 / 12);

      return [
        {
          id: 'tier-personal',
          name: 'Personal',
          href: '/plans/personal/',
          priceMonthly: artistPrice,
          description: 'For individuals or freelancers under $100k USD funding or yearly turnover. Single seat personal license.',
          features: [
            'Up to 12,000 credits a year',
            'Up to 16k max resolution',
            'Up to 100 free assets a month',
            'JPG and RAW formats',
          ],
        },
        {
          id: 'tier-commercial',
          name: 'Commercial',
          href: '/plans/commercial/',
          priceMonthly: indiePrice,
          description: 'For businesses and companies. Includes single-site licence, team management and IP login security features.',
          features: [
            'Up to 72,000 credits a year',
            'Over 50k max resolution',
            'Up to 250 free assets a month',
            'JPG, EXR and RAW formats',
            'Team management + IP login security',
            'Indemnity contracts available',
          ],
        },
      ]
    }
  }
}
</script>